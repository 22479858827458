import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name battery-plus
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgOXY2IiAvPgogIDxwYXRoIGQ9Ik0xMy41IDdIMTZhMiAyIDAgMCAxIDIgMnY2YTIgMiAwIDAgMS0yIDJoLTIuNSIgLz4KICA8cGF0aCBkPSJNMjIgMTF2MiIgLz4KICA8cGF0aCBkPSJNNi41IDE3SDRhMiAyIDAgMCAxLTItMlY5YTIgMiAwIDAgMSAyLTJoMi41IiAvPgogIDxwYXRoIGQ9Ik03IDEyaDYiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/battery-plus
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const BatteryPlus: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 9v6' }],
    ['path', { d: 'M13.5 7H16a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-2.5' }],
    ['path', { d: 'M22 11v2' }],
    ['path', { d: 'M6.5 17H4a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h2.5' }],
    ['path', { d: 'M7 12h6' }],
  ],
];

export default BatteryPlus;
