import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name map-plus
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTEgMTktMS4xMDYtLjU1MmEyIDIgMCAwIDAtMS43ODggMGwtMy42NTkgMS44M0ExIDEgMCAwIDEgMyAxOS4zODFWNi42MThhMSAxIDAgMCAxIC41NTMtLjg5NGw0LjU1My0yLjI3N2EyIDIgMCAwIDEgMS43ODggMGw0LjIxMiAyLjEwNmEyIDIgMCAwIDAgMS43ODggMGwzLjY1OS0xLjgzQTEgMSAwIDAgMSAyMSA0LjYxOVYxMiIgLz4KICA8cGF0aCBkPSJNMTUgNS43NjRWMTIiIC8+CiAgPHBhdGggZD0iTTE4IDE1djYiIC8+CiAgPHBhdGggZD0iTTIxIDE4aC02IiAvPgogIDxwYXRoIGQ9Ik05IDMuMjM2djE1IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/map-plus
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const MapPlus: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm11 19-1.106-.552a2 2 0 0 0-1.788 0l-3.659 1.83A1 1 0 0 1 3 19.381V6.618a1 1 0 0 1 .553-.894l4.553-2.277a2 2 0 0 1 1.788 0l4.212 2.106a2 2 0 0 0 1.788 0l3.659-1.83A1 1 0 0 1 21 4.619V12',
      },
    ],
    ['path', { d: 'M15 5.764V12' }],
    ['path', { d: 'M18 15v6' }],
    ['path', { d: 'M21 18h-6' }],
    ['path', { d: 'M9 3.236v15' }],
  ],
];

export default MapPlus;
