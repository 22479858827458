import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name snowflake
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTAgMjAtMS4yNS0yLjVMNiAxOCIgLz4KICA8cGF0aCBkPSJNMTAgNCA4Ljc1IDYuNSA2IDYiIC8+CiAgPHBhdGggZD0ibTE0IDIwIDEuMjUtMi41TDE4IDE4IiAvPgogIDxwYXRoIGQ9Im0xNCA0IDEuMjUgMi41TDE4IDYiIC8+CiAgPHBhdGggZD0ibTE3IDIxLTMtNmgtNCIgLz4KICA8cGF0aCBkPSJtMTcgMy0zIDYgMS41IDMiIC8+CiAgPHBhdGggZD0iTTIgMTJoNi41TDEwIDkiIC8+CiAgPHBhdGggZD0ibTIwIDEwLTEuNSAyIDEuNSAyIiAvPgogIDxwYXRoIGQ9Ik0yMiAxMmgtNi41TDE0IDE1IiAvPgogIDxwYXRoIGQ9Im00IDEwIDEuNSAyTDQgMTQiIC8+CiAgPHBhdGggZD0ibTcgMjEgMy02LTEuNS0zIiAvPgogIDxwYXRoIGQ9Im03IDMgMyA2aDQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/snowflake
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Snowflake: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm10 20-1.25-2.5L6 18' }],
    ['path', { d: 'M10 4 8.75 6.5 6 6' }],
    ['path', { d: 'm14 20 1.25-2.5L18 18' }],
    ['path', { d: 'm14 4 1.25 2.5L18 6' }],
    ['path', { d: 'm17 21-3-6h-4' }],
    ['path', { d: 'm17 3-3 6 1.5 3' }],
    ['path', { d: 'M2 12h6.5L10 9' }],
    ['path', { d: 'm20 10-1.5 2 1.5 2' }],
    ['path', { d: 'M22 12h-6.5L14 15' }],
    ['path', { d: 'm4 10 1.5 2L4 14' }],
    ['path', { d: 'm7 21 3-6-1.5-3' }],
    ['path', { d: 'm7 3 3 6h4' }],
  ],
];

export default Snowflake;
