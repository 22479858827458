import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name rat
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMgMjJINGEyIDIgMCAwIDEgMC00aDEyIiAvPgogIDxwYXRoIGQ9Ik0xMy4yMzYgMThhMyAzIDAgMCAwLTIuMi01IiAvPgogIDxwYXRoIGQ9Ik0xNiA5aC4wMSIgLz4KICA8cGF0aCBkPSJNMTYuODIgMy45NGEzIDMgMCAxIDEgMy4yMzcgNC44NjhsMS44MTUgMi41ODdhMS41IDEuNSAwIDAgMS0xLjUgMi4xbC0yLjg3Mi0uNDUzYTMgMyAwIDAgMC0zLjUgMyIgLz4KICA8cGF0aCBkPSJNMTcgNC45ODhhMyAzIDAgMSAwLTUuMiAyLjA1MkE3IDcgMCAwIDAgNCAxNC4wMTUgNCA0IDAgMCAwIDggMTgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/rat
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Rat: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M13 22H4a2 2 0 0 1 0-4h12' }],
    ['path', { d: 'M13.236 18a3 3 0 0 0-2.2-5' }],
    ['path', { d: 'M16 9h.01' }],
    [
      'path',
      {
        d: 'M16.82 3.94a3 3 0 1 1 3.237 4.868l1.815 2.587a1.5 1.5 0 0 1-1.5 2.1l-2.872-.453a3 3 0 0 0-3.5 3',
      },
    ],
    ['path', { d: 'M17 4.988a3 3 0 1 0-5.2 2.052A7 7 0 0 0 4 14.015 4 4 0 0 0 8 18' }],
  ],
];

export default Rat;
